import React, { useState } from "react";
import info from "./img/info.png";
import words from "./img/sanat.png";
import pics from "./img/kuvat.png";
import logo from "./img/logo.png";
import "./App.css";
import styled from "styled-components";
import Front from "./pages/Front";
import Info from "./pages/Info";
import Words from "./pages/Words";
import Pics from "./pages/Pics";

const Header = styled.div`
  display: flex;
  align-items: end;
`;

const Logo = styled.img`
  margin-top: 25px;
  width: 50%;
  max-width: 1000px;
  cursor: pointer;
`;

const Img = styled.img`
  height: 60px;
  padding-right: 55px;
  cursor: pointer;
`;

const KuvatImg = styled(Img)`
  height: 80px;
`;

type Page = "front" | "info" | "kuvat" | "sanat";

const getPage = (page: Page) => {
  if (page === "front") {
    return <Front />;
  } else if (page === "info") {
    return <Info />;
  } else if (page === "kuvat") {
    return <Pics />;
  } else if (page === "sanat") {
    return <Words />;
  }
  return null;
};

function App() {
  const [page, setPage] = useState<Page>("front");
  if (true) {
    return (
      <div className="App">
        <header className="App-header">
          <Logo src={logo} alt="kangastus.net" />
        </header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1 onClick={() => setPage("front")}>kangastus.net</h1>
        <Header>
          <Img src={info} onClick={() => setPage("info")} alt="Info" />
          <KuvatImg src={pics} onClick={() => setPage("kuvat")} alt="Kuvat" />
          <Img src={words} onClick={() => setPage("sanat")} alt="Sanat" />
        </Header>
        {getPage(page)}
      </header>
    </div>
  );
}

export default App;
